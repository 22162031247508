import React, { Component, Fragment } from 'react';
import Content from './content/content_component';

export default class LandingComponent extends Component {
    render() {
        return (
            <Fragment>
                <Content/>
            </Fragment>
        )
    }
}