import triggerSimpleAjax from "./httpHelper";

export default function triggerMail(requestData) {
    let tableData = [];
    const { contents } = requestData;
    // eslint-disable-next-line
    Object.keys(contents.table_data).map((keyValue) => {
        tableData.push({
            key: keyValue,
            value: contents.table_data[keyValue]
        })
    });
    requestData.contents.table_data = tableData;
    return new Promise((res, rej) => {
        let url = `https://busyhubs.herokuapp.com/api/sendMail`;
        triggerSimpleAjax(
            url,
            'POST',
            requestData,
            response => {
                res('Promised resolved from fetch');
            },
            error => {
                rej('Failed');
            }
        );
    });
}